// extracted by mini-css-extract-plugin
export var banner = "index-module--banner--613a1";
export var card = "index-module--card--5f6ab";
export var cardC = "index-module--cardC--6d5e0";
export var cards = "index-module--cards--cdd75";
export var columnsFour = "index-module--columnsFour--a1157";
export var columnsThree = "index-module--columnsThree--2027a";
export var containerWrapper = "index-module--containerWrapper--542d1";
export var cta = "index-module--cta--6bce7";
export var ctaLight = "index-module--ctaLight--afd19";
export var ctaS = "index-module--ctaS--51cc9";
export var legend = "index-module--legend--6506b";
export var light = "index-module--light--781a8";
export var link = "index-module--link--56c91";
export var statistics = "index-module--statistics--d72fc";
export var tag = "index-module--tag--6814e";
export var tokenomics = "index-module--tokenomics--d0a62";