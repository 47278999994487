import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../components/layout';
import * as styles from './index.module.css';
import { getLink } from '../utils/utils';

class RootIndex extends React.Component {
    render() {
        const links = get(this, 'props.data.allContentfulLink.nodes') || [{}];
        
        return (
            <Layout location={this.props.location} links={links}>
                <div className={styles.containerWrapper}>
                    <div className={styles.banner}>
                        <span className={styles.legend}>Real-time execution - Fully non-custodial - AI/ML powered algorithms</span>
                        <h1>The most sophisticated bot unlocking <b>collective wealth²</b></h1>
                        <a
                            href={getLink(links, 'Twitter')}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.cta}
                        >
                            Open in Telegram
                            <img
                                alt="Telegram"
                                src="/telegram-round.svg"
                                width="32px"
                            />
                        </a>
                        <a
                            href={getLink(links, 'Tutorial')}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.ctaLight}
                        >
                            LEARN MORE
                        </a>
                    </div>
                    <div className={styles.cards}>
                        <div className={styles.light}>
                            How does 4Runner Bot work?
                        </div>
                        <div className={styles.card}>
                            <img alt="Icon" src="/icon.png" />
                            <h3>Narrative Driven</h3>
                            <span>4Runner operates narrative-driven trading bots like friend.tech, utilizing advanced algorithms to purchase keys and tokens.</span>
                        </div>
                        <div className={styles.card}>
                            <img alt="Icon" src="/icon.png" />
                            <h3>Systematic Strategies</h3>
                            <span>Our strategies consistently yield profitable results, as our trading bots execute sales at predefined returns or specific criteria.</span>
                        </div>
                        <div className={styles.card}>
                            <img alt="Icon" src="/icon.png" />
                            <h3>Token Based</h3>
                            <span>In Q4 2023, we will launch the 4Runner token, extending an opportunity for token holders to reap the profits of our trading bot activities.</span>
                        </div>
                        <div className={styles.card}>
                            <img alt="Icon" src="/icon.png" />
                            <h3>Revenue Distribution</h3>
                            <span>Holders will earn a share of the sales tax generated from token purchases and sales, as well as a share of the revenue generated by our trading bots.</span>
                        </div>
                        <div className={styles.cardC}>
                            <h3>Our <a
                                href="https://www.friend.tech/"
                                target="_blank"
                                rel="noreferrer"
                                className={styles.link}
                            >friend.tech</a> bot monitors crypto influencers in real-time and automates buying efficiently</h3>
                            <a
                                href={getLink(links, 'Tutorial')}
                                target="_blank"
                                rel="noreferrer"
                                className={styles.ctaS}
                            >LEARN MORE</a>
                        </div>                     
                    </div>
                    <div className={styles.tokenomics}>
                        <h2>Tokenomics</h2>
                        <p>4Runner Bot tax will help the project grow as well as fund the initial sniper pool for trading.</p>
                        <div className={styles.columnsFour}>
                            <div className={styles.card}>
                                <span className={styles.tag}>Token Supply</span>
                                <h3>10,000,000 Tokens</h3>
                            </div>
                            <div className={styles.card}>
                                <span className={styles.tag}>TAX</span>
                                <h3>4% Buy / Sell Fees</h3>
                            </div>
                            <div className={styles.card}>
                                <span className={styles.tag}>Revenue Share</span>
                                <h3>80% of all revenues are distributed to token holders</h3>
                            </div>
                            <div className={styles.card}>
                                <span className={styles.tag}>Tech</span>
                                <h3>AI/ML powered 4Runner bot runs 24/7 in real-time</h3>
                            </div>
                            <div className={styles.card}>
                                <span className={styles.tag}>Token Launch</span>
                                <h3>Fair Launch in Q4 2023 with bot sniping prevention</h3>
                            </div>
                            <div className={styles.card}>
                                <span className={styles.tag}>Team Allocation</span>
                                <h3>Only 5% of the total token supply will go to the team</h3>
                            </div>
                            <div className={styles.card}>
                                <span className={styles.tag}>Loyalty Benefits</span>
                                <h3>Up to 2x multiplier on your earnings</h3>
                            </div>
                            <div className={styles.card}>
                                <span className={styles.tag}>More</span>
                                <h3>Learn more about 4Runner with our knowledge base</h3>
                            </div>
                        </div>
                    </div>                    
                    <div className={styles.statistics}>
                        <h2>Statistics</h2>
                        <div className={styles.columnsThree}>
                            <div className={styles.card}>
                                <h3>$6,741,046</h3>
                                <span>Total Trading Volumes</span>
                            </div>
                            <div className={styles.card}>
                                <h3>71%</h3>
                                <span>Trade Success Rate</span>
                            </div>
                            <div className={styles.card}>
                                <h3>98%</h3>
                                <span>Return on Investment (ITD)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default RootIndex;

export const itemsQuery = graphql`
    query ItemQuery {
        allContentfulLink {
            nodes {
                title
                link
                shouldHideIcon
            }
        }
    }
`;
